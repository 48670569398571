<template>
    <tr :class="getClass()" @click="onClick">
        <slot name="default"></slot>
    </tr>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Provide, Inject, Prop } from '@/helpers/Decorators';

@Options({
    name: 'list-view-utils-row',
    inheritAttrs: false
})
export default class Row extends Vue
{
    @Prop()
    @Provide('index')
    public index: number;

    @Prop()
    @Provide('item')
    public item: any;

    @Inject('loaded')
    public loaded: boolean;

    @Inject('row-click')
    public rowClick: (item: any) => void;

    @Inject('row-class')
    public rowClass: (item: any) => Record<string, boolean> | string[] | string;

    public getClass(): any
    {
        if (this.rowClass)
        {
            return this.rowClass(this.item);
        }

        return {};
    }

    public onClick(): void
    {
        if (this.rowClick && this.loaded)
        {
            return this.rowClick(this.item);
        }
    }
}
</script>
