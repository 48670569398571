import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/CustomPropertiesService";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            citiesIds: [],
            showNonActive: false
        }),
        pager: new Pager(1, 20, 'propertyName', 'ASC')
    }
};
