<template>
    <span v-if="translation !== false">
        <textarea ref="textareaRef" class="form-control" :value="translatedValue || currentValue" @input="updateModel" :disabled="translatedValue !== null" v-bind="$attrs"></textarea>
        <translate :translate-text="currentValue" @translatedText="(translated: string) => translatedValue = translated" />
    </span>
    <textarea ref="textareaRef" v-else class="form-control" :value="currentValue" @input="updateModel" v-bind="$attrs"></textarea>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-textarea',
    inheritAttrs: false,
})
export default class IdeoFormTextarea extends Vue
{
    public textareaRef: any;

    public currentValue: string = null;

    public translatedValue: string = null;

    @Prop({ default: false }) public translation: boolean;

    @Prop()
    public value: string;

    @Prop()
    public modelValue: string;

    @Emit('input')
    @Emit('update:modelValue')
    public updateModel(e: Event): string
    {
        const target = e.target as HTMLInputElement;

        this.currentValue = target.value;

        return this.currentValue;
    }

    @Watch('value', { immediate: true })
    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }

    @Emit('paste')
    public watchPaste(event: any): void
    {
        return event;
    }

    public mounted(): void
    {
        this.textareaRef = this.$refs.textareaRef;
        this.$refs.textareaRef.addEventListener('paste', this.watchPaste);
    }

    public unmounted(): void
    {
        this.textareaRef.removeEventListener('paste', this.watchPaste);
    }
}
</script>
<style scoped>
span{
    display: contents;
}
</style>