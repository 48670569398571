import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Option, Pagination, Resource, Statement, KeyValue } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { ListItemModel as RegionModel } from '@/modules/renter/regions/services/RegionsService';
import { ListItemModel as CityModel } from '@/modules/renter/cities/services/CitiesService';
import { ListItemModel as DistrictModel } from '@/modules/renter/districts/services/DistrictsService';
import { AccountType } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { $t } from "@/plugins/localization";

/**
 * UsersService
 */
export default class UsersService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/renters/users', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/users/autocomplete', {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/users/autocomplete/${id}`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompletePublicIdList(search: string, limit: number = 10, departments: string, showAllRecords: boolean = true, propertyPublicId: string | null = null, customPropertyPublicId: string | null = null, userOutsideCurrentDistricts: boolean = false): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/renters/users/autocomplete', {
            params: {
                search: search,
                limit: limit,
                departments: departments,
                allRecordsVisible: showAllRecords,
                propertyPublicId: propertyPublicId,
                customPropertyPublicId: customPropertyPublicId,
                userOutsideCurrentDistricts,
            }
        })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetchAutocompletePublicIdModel(publicId: string): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/renters/users/autocomplete/${publicId}`)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(publicId: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/renters/users/${publicId}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/renters/users`, model)).data;
    }

    /**
     * @param publicId string
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(publicId: string, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/renters/users/${publicId}`, model)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Statement>
     */
    public static async remove(publicId: string): Promise<Statement>
    {
        return (await axios.delete(`admin/renters/users/${publicId}`)).data as Statement;
    }

    /**
     * @param publicId string
     * @param resource FileResource
     *
     * @returns Promise<Statement>
     */
    public static async updateFacePicture(publicId: string, resource: FileResource): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/renters/users/${publicId}/avatar`, resource)).data;
    }

    /**
     * @param publicId string
     * @param resource FileResource
     *
     * @returns Promise<Statement>
     */
    public static async getDepartment(): Promise<Array<string>>
    {
        return (await axios.get<Array<string>>(`admin/renters/users/departments`)).data;
    }

    /**
     * @param playerId string
     * @param set boolean
     *
     * @returns Promise<Statement>
     */
    public static async setSubscription(playerId: string, set: boolean): Promise<Statement>
    {
        if (set)
            return (await axios.post<Statement>(`admin/onesignal`, { playerId })).data;
        else
            return (await axios.delete<Statement>(`admin/onesignal/${playerId}`)).data;
    }
}

export enum UsersRangeEnum {
    All = 0,
    Admin = 1,
    Inactive = 2,
    Active = 3
}

export interface ListItemModel
{
    id: number;
    publicId: string;
    dateCreatedUtc: DateTime;
    userName: string;
    email: string;
    givenName: string;
    surname: string;
    isAdmin: boolean;
    facePictureUrl: string;
    accountType: AccountType;
    phoneNumber: number;
    departments: DepartmentEnum[];
    cities: CityItemModel[];
    districts: DistrictModel[];
    citiesNames: string;
    IsActive: boolean;
    color: string;
}

interface CityItemModel
{
    city: string;
    id: number;
    publicId: string;
    region: string;
    regionId: number;
}

export interface FilterModel
{
    id: string;
    userName: string;
    givenName: string;
    surname: string;
    roleId?: number;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    range: UsersRangeEnum;
    tab: string;
    cityPublicId: string;
    distrcitPublicId: string;
}

export interface FormModel
{
    id: number;
    publicId: string;
    userName: string;
    email: string;
    emailConfirmed: boolean;
    newPassword: string;
    repeatPassword: string;
    givenName: string;
    surname: string;
    roles: string[];
    isAdmin: boolean;
    facePicture?: FileResource;
    forcePasswordChange: boolean;
    lockoutEndDateUtc?: DateTime;
    failedAttempts: number;
    accountType: AccountType;
    accountPayload: string;
    accountDisabledUtc?: DateTime;
    permissions: KeyValue<string, Record<string, string>>[];
    unitId?: number;
    companyId?: number;
    companyPublicId: string;
    departments: string[];
    regions: RegionModel[];
    phoneNumber: number;
    cities: CityModel[];
    preferedLanguage: string;
    districts: DistrictModel[];
    color: string;
}

export interface AuthorModel
{
    id: number;
    userName: string;
    givenName: string;
    surname: string;
    email: string;
    facePictureUrl: string;
}

export interface OneSignalSubscriptionModel
{
    playerId: string;
}
export interface OneSignalErrorStatement<T> {
    code: number;
    errors: T;
}

enum DepartmentEnum {
    Cleaning = 'Cleaning',
    Maintenance = 'Maintenance',
    MainMaintenance = 'MainMaintenance',
    Inspection = 'Inspection',
    Driver = 'Driver',
    MainDriver = 'MainDriver',
    Coordinator = 'Coordinator',
    CoordinatorMaintenance = 'CoordinatorMaintenance',
    CoordinatorCleaning = 'CoordinatorCleaning',
    ExternalManager = 'ExternalManager',
    MainCoordinator = 'MainCoordinator',
    AccountsOffice = 'AccountsOffice',
    RegionChief = 'RegionChief',
    CustomerService = 'CustomerService',
    BackOffice = 'BackOffice',
    OwnerService = 'OwnerService',
    HumanResources = 'HumanResources',
    Warehouseman = 'Warehouseman'
}

const DepartmentOptions = (): Option[] => ([
    { value: DepartmentEnum.Cleaning, text: $t('[[[Housekeeping (HK)]]]') },
    { value: DepartmentEnum.Maintenance, text: $t('[[[Konserwacja]]]') },
    { value: DepartmentEnum.MainMaintenance, text: $t('[[[Główny Konserwator]]]') },
    { value: DepartmentEnum.Inspection, text: $t('[[[Inspekcja]]]') },
    { value: DepartmentEnum.Driver, text: $t('[[[Kierowca]]]') },
    { value: DepartmentEnum.MainDriver, text: $t('[[[Kierowca Główny]]]')},
    { value: DepartmentEnum.Coordinator, text: $t('[[[Koordynator]]]') },
    { value: DepartmentEnum.CoordinatorMaintenance, text: $t('[[[Koordynator Konserwacji]]]') },
    { value: DepartmentEnum.CoordinatorCleaning, text: $t('[[[Koordynator HK]]]') },
    { value: DepartmentEnum.ExternalManager, text: $t('[[[Manager Firmy Zewnętrznej]]]') },
    { value: DepartmentEnum.MainCoordinator, text: $t('[[[Koordynator Główny]]]') },
    { value: DepartmentEnum.AccountsOffice, text: $t('[[[Rozliczenia]]]') },
    { value: DepartmentEnum.RegionChief, text: $t('[[[Szef Regionu]]]') },
    { value: DepartmentEnum.CustomerService, text: $t('[[[Obsługa Klienta (BOK)]]]') },
    { value: DepartmentEnum.BackOffice, text: $t('[[[Back Office (BO)]]]') },
    { value: DepartmentEnum.OwnerService, text: $t('[[[Obsługa Właściciela (OW)]]]') },
    { value: DepartmentEnum.HumanResources, text: $t('[[[HR]]]') },
    { value: DepartmentEnum.Warehouseman, text: $t('[[[Magazynier]]]')}
]);

const DepartmentOptionsForExternalManager: string[] = [DepartmentEnum.Cleaning, DepartmentEnum.CoordinatorCleaning, DepartmentEnum.CoordinatorMaintenance, DepartmentEnum.Maintenance];

const DepartmentName = (value: DepartmentEnum): string => DepartmentOptions().find(d => d.value === value)?.text || DepartmentEnum[value];

export {
    DepartmentEnum,
    DepartmentOptions,
    DepartmentOptionsForExternalManager,
    DepartmentName
};
