<template>
    <div class="d-flex pt-2" :class="getClass(index)" @click="onClick">
        <div class="ms-2" v-if="images().length > 0">
            <vnodes :vnodes="images()" />
        </div>
        <div class="d-flex flex-column flex-grow-1 ms-2">
            <div class="d-flex">
                <div class="flex-grow-1 mb-1">
                    <vnodes :vnodes="header()" />
                </div>
                <div class="mx-2">
                    <vnodes :vnodes="buttons()" />
                </div>
            </div>
            <div class="mb-1" v-if="label().length > 0">
                <vnodes :vnodes="label()" />
            </div>
            <div class="pb-1" v-if="items().length > 0">
                <div :class="itemsClassNames">
                    <vnodes :vnodes="items()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Provide, Inject, Prop } from '@/helpers/Decorators';
import { VNode } from 'vue';

@Options({
    name: 'list-view-utils-flex',
    inheritAttrs: false,
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    }
})
export default class Flex extends Vue
{
    @Prop()
    @Provide('index')
    public index: number;

    @Prop()
    @Provide('item')
    public item: any;

    @Inject('loaded')
    public loaded: boolean;

    @Inject('row-click')
    public rowClick: (item: any) => void;

    @Inject('row-class')
    public rowClass: (item: any) => Record<string, boolean> | string[] | string;

    public getClass(index: number): any
    {
        let rowClass = { 'bg-light': index % 2 == 0 };

        if (this.rowClass)
        {
            rowClass = Object.assign(rowClass, this.rowClass(this.item));
        }

        return rowClass;
    }

    public onClick(): void
    {
        if (this.rowClick && this.loaded)
        {
            return this.rowClick(this.item);
        }
    }

    public filter(tagName: string): any[]
    {
        return this.travel(this.$slots.default(), tagName);
    }

    public travel(elements: VNode[], tagName: string): VNode[]
    {
        let results: VNode[] = [];

        elements.forEach((element) =>
        {
            const type: any = element.type;

            if (typeof type !== 'symbol' && type.name === tagName)
            {
                results.push(element);
            }
            else if (element.children && Array.isArray(element.children) && element.children.length > 0)
            {
                results = [...results, ...this.travel(element.children as VNode[], tagName)];
            }
        });

        return results;
    }

    public header(): any[]
    {
        return this.filter('list-view-header');
    }

    public items(): any[]
    {
        return this.filter('list-view-item');
    }

    public label(): any[]
    {
        return this.filter('list-view-label');
    }

    public buttons(): any[]
    {
        return this.filter('list-view-buttons');
    }

    public images(): any[]
    {
        return this.filter('list-view-image');
    }

    public get itemsClassNames(): string
    {
        return `row row-cols-1 row-cols-sm-2 row-cols-md-3`;
    }
}
</script>
