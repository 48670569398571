<template>
    <div class="notifications d-flex align-self-center">
        <ideo-dropdown
            ref="notificationDropdown"
            variant="light"
            class="notifications-dropdown"
            toggle-class="btn-dropdown-white ms-1"
            right
            no-caret
            :title="$t('[[[Powiadomienia]]]')"
        >
            <template #button-content>
                <i class="fa-solid fa-bell"></i>
                <span class="badge badge-success ms-auto" v-if="total > 0">{{
                    total
                }}</span>
            </template>
            <ideo-dropdown-item>
                <div
                    class="text-center p-2"
                    v-if="total == 0"
                    @click.prevent="handleClickToggleNotification"
                >
                    {{ $t("[[[Brak nowych powiadomień.]]]") }}
                </div>
                <div v-else class="notifications-scroll-container">
                    <div
                        class="notifications-dropdown-element"
                        v-for="message in messagesList"
                        :key="message.uid"
                        @click.prevent="
                            () => handleClickNotification(message)
                        "
                    >
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <i
                                    class="icon fal fa-check-circle text-success"
                                    v-if="message.payload.level == 'success'"
                                ></i>
                                <i
                                    class="icon fal fa-info-circle text-info"
                                    v-if="message.payload.level == 'info'"
                                ></i>
                                <i
                                    class="icon fal fa-exclamation-circle text-warning"
                                    v-if="message.payload.level == 'warning'"
                                ></i>
                                <i
                                    class="icon fal fa-exclamation-triangle text-danger"
                                    v-if="message.payload.level == 'error'"
                                ></i>
                            </div>
                            <div class="flex-fill">
                                <div
                                    :class="[
                                        'text-dark',
                                        `notification-meta-${message.payload.data.meta?.toLowerCase()}`,
                                    ]"
                                    v-html="message.payload.data.message"
                                ></div>
                                <div class="text-muted small">
                                    {{
                                        $filters.datetime(
                                            message.dateCreatedUtc
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="dropdown-item text-center small border-top pt-2 pb-0 mt-3 d-flex justify-content-center"
                        @click="toggle = false"
                    >
                        <router-link
                            :to="{ name: 'core-account-notifications' }"
                            @click="handleClickToggleNotification"
                        >
                            {{ $t("[[[Zobacz wszystkie]]]") }}
                        </router-link>
                        <a href="#" @click.prevent="notificationsAllRead">{{
                            $t("[[[Oznacz wszystkie jako przeczytane]]]")
                        }}</a>
                    </div>
                </div>
            </ideo-dropdown-item>
        </ideo-dropdown>
        <ideo-dropdown
            ref="messagesDropdown"
            variant="light"
            class="notifications-dropdown"
            toggle-class="btn-dropdown-white ms-1"
            right
            no-caret
            :title="$t('[[[Wiadomości]]]')"
        >
            <template #button-content>
                <i class="fa-solid fa-message"></i>
                <span
                    class="badge badge-success ms-auto"
                    v-if="chatTotal > 0"
                >{{ chatTotal }}</span>
            </template>
            <ideo-dropdown-item>
                <div
                    class="text-center p-2"
                    v-if="chatTotal == 0"
                    @click.prevent="handleClickMessage"
                >
                    {{ $t("[[[Brak nowych wiadomości.]]]") }}
                </div>
                <div v-else>
                    <div
                        class="notifications-dropdown-element"
                        v-for="message in chatMessagesList"
                        :key="message.uid"
                        @click.prevent="
                            () => {
                                chatRead(message);
                                redirect(routeTo(message.payload.data));
                                handleClickToggleMessages();
                            }
                        "
                    >
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <i
                                    class="icon fal fa-check-circle text-success"
                                    v-if="message.payload.level == 'success'"
                                ></i>
                                <i
                                    class="icon fal fa-info-circle text-info"
                                    v-if="message.payload.level == 'info'"
                                ></i>
                                <i
                                    class="icon fal fa-exclamation-circle text-warning"
                                    v-if="message.payload.level == 'warning'"
                                ></i>
                                <i
                                    class="icon fal fa-exclamation-triangle text-danger"
                                    v-if="message.payload.level == 'error'"
                                ></i>
                            </div>
                            <div class="flex-fill">
                                <div
                                    class="text-dark"
                                    v-html="message.payload.data.message"
                                ></div>
                                <div class="text-muted small">
                                    {{
                                        $filters.datetime(
                                            message.dateCreatedUtc
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="dropdown-item text-center small border-top pt-2 pb-0 mt-3"
                        @click="toggle = false"
                    >
                        <router-link to="" @click.prevent="chatAllRead()">
                            {{ $t("[[[Oznacz widoczne jako przeczytane]]]") }}
                        </router-link>
                    </div>
                </div>
            </ideo-dropdown-item>
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import {
    FilterModel,
    MessageModel,
} from "@/modules/core/common/services/NotificationsService";
import { Pagination } from "@/helpers/Interfaces";
import { Invoke, Listen } from "@/plugins/signalr";
import Pager from "@/helpers/Pager";
import {
    NotificationNavigation,
    NotificationNavigationType,
} from "@/helpers/NotificationNavigation";
import { RouteLocationRaw } from "vue-router";
import { Watch, Ref } from "@/helpers/Decorators";
import setAppTitle, {
    AppTitleEnum,
} from "@/modules/renter/common/helpers/appTitle";
import NotificationsService from "@/modules/core/common/services/NotificationsService";
import IdeoDropdown from "@/components/bootstrap/dropdown/IdeoDropdown.vue";

@Options({})
export default class Notifications extends Vue
{
    @Ref()
    public notificationDropdown: () => IdeoDropdown;

    @Ref()
    public messagesDropdown: () => IdeoDropdown;

    public timeout: any = null;
    public messages: MessageModel[] = [];
    public chatMessages: MessageModel[] = [];
    public total: number = 0;
    public chatTotal: number = 0;
    public toggle: boolean = false;

    public get messagesList(): MessageModel[]
    {
        return this.messages.slice(0, 10);
    }

    public get chatMessagesList(): MessageModel[]
    {
        return this.chatMessages.slice(0, 10);
    }

    public async created(): Promise<void>
    {
        this.loadData();
    }

    public mounted(): void
    {
        window.addEventListener("click", this.click);
    }

    public unmounted(): void
    {
        window.removeEventListener("click", this.click);
    }

    public routeTo(navigation: NotificationNavigationType): RouteLocationRaw
    {
        return NotificationNavigation(navigation);
    }

    public async loadData(): Promise<boolean>
    {
        try
        {
            const result = await this.getNotifications(
                { isRead: false },
                new Pager(1, 10, "Id", "DESC").data(),
                this.preferedLanguage
            );

            this.messages = result.items;
            this.total = result.totalRows;

            const chatResult = await this.getChatNotifications(
                { isRead: false },
                new Pager(1, 10, "Id", "DESC").data(),
                this.preferedLanguage
            );

            this.chatMessages = chatResult.items;
            this.chatTotal = chatResult.totalRows;
        }
        catch (ex)
        {
            //#150012
            // this.handleException(ex, {
            //     0: (ex: any) =>
            //         this.$alert.danger(
            //             this.$t("[[[Nie udało się pobrać powiadomień.]]]")
            //         ),
            // });

            return false;
        }

        return true;
    }

    public async notificationsAllRead(): Promise<void>
    {
        try
        {
            await NotificationsService.markAllNotificationsAsRead();
            await this.loadData();
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public async read(message: MessageModel): Promise<void>
    {
        if (!message.isRead)
        {
            message.isRead = true;

            await this.markAsRead(message.uid);

            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => this.loadData(), 1000);
        }
    }

    public async chatRead(message: MessageModel): Promise<void>
    {
        if (!message.isRead)
        {
            message.isRead = true;

            await this.markChatNotificationAsRead(message.uid);

            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => this.loadData(), 1000);
        }
    }

    public async chatAllRead(): Promise<void>
    {
        for (let index = 0; index < this.chatMessages.length; index++)
        {
            const message = this.chatMessages[index];

            message.isRead = true;

            await this.markChatNotificationAsRead(message.uid);
        }

        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => this.loadData(), 1000);
    }

    @Invoke("basicNotifications")
    public getNotifications: (
        filter: FilterModel,
        pager: any,
        lang: string
    ) => Promise<Pagination<MessageModel>>;

    @Invoke("basicNotifications")
    public markAsRead: (uid: string) => Promise<void>;

    @Invoke("chat")
    public getChatNotifications: (
        filter: FilterModel,
        pager: any,
        lang: string
    ) => Promise<Pagination<MessageModel>>;

    @Invoke("chat")
    public markChatNotificationAsRead: (uid: string) => Promise<void>;

    @Listen("notifications")
    public async messageReceived(payload: any): Promise<void>
    {
        this.loadData();

        if (payload.data.isChatNotification)
            setAppTitle(AppTitleEnum.NewMessage, true);

        this.$alert[payload.level](payload.data.message, {
            onClick: () =>
                payload.data.navigation
                    ? this.redirect(this.routeTo(payload.data))
                    : null,
        });
    }

    public forceDownload(url: string, fileName: string): void
    {
        location.href = this.$filters.baseurl(`${url}?dl`);
    }

    public click(): void
    {
        setAppTitle(AppTitleEnum.Default);
        this.toggle = false;
    }

    @Watch("$store.state.renter.chat.removeNotification")
    private async onRemoveNotification(dataPublicId: string): Promise<void>
    {
        if (dataPublicId)
        {
            const notification: MessageModel = this.chatMessagesList.find(
                ({ payload: { data } }: any) => data.publicId === dataPublicId
            );

            if (notification) this.chatRead(notification);

            this.$store.state.renter.chat.removeNotification = null;
        }
    }

    public get preferedLanguage(): string
    {
        return this.$store.state.auth.identity.preferedLanguage;
    }

    public handleClickNotification(message: MessageModel): void
    {
        this.read(message);
        this.redirect(this.routeTo(message.payload.data));
        this.handleClickToggleNotification();
    }

    public handleClickToggleNotification(): void
    {
        this.notificationDropdown().hide();
    }

    public handleClickMessage(): void
    {
        this.$router.push({ name: "chat-myrooms" });
        this.handleClickToggleMessages();
    }

    public handleClickToggleMessages(): void
    {
        this.messagesDropdown().hide();
    }
}
</script>

<style lang="scss" scoped>

    .notifications {
        .dropdown-item {
            padding: 0 !important;

            &:hover {
                background-color: unset !important;
            }
        }

        &-dropdown-element {
            padding: 0.375rem 1.25rem;

            &:hover {
                color: var(--cui-dropdown-link-hover-color);
                background-color: var(--cui-dropdown-link-hover-bg);
            }
        }
    }

</style>

<style lang="scss">
.notifications-dropdown .dropdown-menu {
    margin-top: 10px !important;
}

.notifications {
    .dropdown-menu {
        width: 300px;
    }
    .dropdown-item {
        white-space: normal;
    }
}

.notification-meta {
    &-bold {
        font-weight: bold;
    }
}

@include media-breakpoint-only(xs) {
    .dropdown-menu-right {
        right: -64px;
    }
    .dropdown-menu.arrow.dropdown-menu-right:before {
        right: 85px;
    }
    .dropdown-menu.arrow.dropdown-menu-right:after {
        right: 86px;
    }
}

.notifications-scroll-container {
    overflow: auto;
    max-height: 85vh;
}


</style>
