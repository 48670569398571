import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            id: '',
            visible: false,
            range: 'active', // [all,admin,inactive,active]
            userName: '',
            givenName: '',
            roleId: null,
            unitId: null,
            unitsBelow: true,
            surname: '',
            dateFromUtc: null,
            dateDueUtc: null,
            tab: 'account',
            cityPublicId: null,
            districtPublicId: null,
        }),
        pager: new Pager(1, 20, 'Surname', 'ASC')
    }
};
